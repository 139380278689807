import React, { Component } from 'react'

class Footer extends Component{
    render(){
        return(
            <div>
                <footer className="footer-setion">
                    <div className="footer-top pt-5 pb-5">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-lg-4 mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <img src="img/logo-color-1x.png" alt="footer logo" width="180" className="img-fluid mb-3" />
                                        <p>Holisticly empower premium architectures without value-added ideas. Seamlessly evolve
                                            cross-platform experiences and open-source meta-services.</p>

                                        <ul className="list-inline">
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Privacy
                                                policy</a></li>
                                            <li className="list-inline-item"><span className="ti-arrow-circle-right mr-2"></span> <a href="/#">Terms
                                                and Conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Our location</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>Address:</strong>
                                                121 King Melbourne
                                                <br />Australia 3000
                                            </li>
                                            <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>Phone:</strong> <a
                                                    href="tel:+61283766284"> +61 2 8376 6284</a></li>
                                            <li className="mb-2"><span className="ti-arrow-circle-right mr-2"></span><strong>Email:</strong><a
                                                    href="mailto:mail@example.com"> mail@example.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-nav-wrap">
                                        <h5 className="mb-3">Quick links</h5>
                                        <ul className="list-unstyled">
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/about-us">About Us</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/services">Our Services</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/pricing">Pricing</a></li>
                                            <li className="mb-2"><i className="ti-arrow-circle-right mr-2"></i> <a href="/contact">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom gray-light-bg pt-4 pb-4">
                        <div className="container">
                            <div className="row text-center text-md-left align-items-center">
                                <div className="col-md-6 col-lg-5"><p className="text-md-left copyright-text pb-0 mb-0">Copyrights © 2019. All
                                    rights reserved by
                                    <a href="/#">ThemeTags</a></p></div>
                                <div className="col-md-6 col-lg-7">
                                    <ul className="social-list list-inline list-unstyled text-md-right">
                                        <li className="list-inline-item"><a href="/#" target="_blank" title="Facebook"><span
                                                className="ti-facebook"></span></a></li>
                                        <li className="list-inline-item"><a href="/#" target="_blank" title="Twitter"><span
                                                className="ti-twitter"></span></a></li>
                                        <li className="list-inline-item"><a href="/#" target="_blank"
                                                                        title="Instagram"><span className="ti-instagram"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;