import React from "react";
import _data from "../../data";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    const tempHero = {};
    tempHero.title = _data.hero2.title;
    tempHero.description = _data.hero2.description;
    tempHero.bgImage = _data.hero3.bgImage;
    tempHero.images = _data.hero3.images;
    this.setState({
      hero: tempHero
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section hero-section-2 ptb-100"
          style={{
            background: `url('img/curve-bg.jpg')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "center / cover"
          }}>
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left ptb-100">
                  <h1>{this.state.hero.title}</h1>
                  <p className="lead">{this.state.hero.description}</p>

                  <a href="/#" className="btn solid-btn btn-primary">
                    Contact with us
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="hero-animation-img">
                  <img
                    className="img-fluid d-block animation-one"
                    src={this.state.hero.images ? this.state.hero.images.img4 : ""}
                    alt="animation"
                  />
                  <img
                    className="img-fluid d-none d-lg-block animation-two"
                    src={this.state.hero.images ? this.state.hero.images.img1 : ""}
                    alt="animation"
                    width="120"
                  />
                  <img
                    className="img-fluid d-none d-lg-block animation-three"
                    src={this.state.hero.images ? this.state.hero.images.img2 : ""}
                    alt="animation"
                    width="120"
                  />
                  <img
                    className="img-fluid d-none d-lg-block animation-four"
                    src={this.state.hero.images ? this.state.hero.images.img3 : ""}
                    alt="animation"
                    width="230"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HeroSection;
