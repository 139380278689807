import React from "react";
import _data from "../../data";

class HeroSection4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {},
      trustedCompany: []
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      hero: _data.hero4,
      trustedCompany: _data.trustedCompanyGray
    });
  }

  render() {
    return (
      <React.Fragment>
        {/* start hero section */}
        <section
          className="hero-section background-img ptb-100"
          style={{
            background: `url(${this.state.hero.bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "center / cover"
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-8 col-lg-7">
                <div className="hero-content-left text-white text-center mt-5 ptb-100">
                  <h1 className="text-white">{this.state.hero.title}</h1>
                  <p className="lead">{this.state.hero.description}</p>

                  <a href="/#" className="btn solid-btn">
                    Contact with us
                  </a>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-md-12">
                <div className="client-section-wrap d-flex flex-row align-items-center">
                  <p className="lead mr-5 mb-0 text-white">
                    Trusted by companies like:
                  </p>
                  <ul className="list-inline justify-content-between">
                    {(this.state.trustedCompany || []).map((image, index) => {
                      return (
                        <li className="list-inline-item" key={index}>
                          <img
                            src={image}
                            width="85"
                            alt="client"
                            className="img-fluid"
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end hero section */}
      </React.Fragment>
    );
  }
}

export default HeroSection4;
